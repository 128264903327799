import * as React from "react";
import { useRouter } from "next/router";

import Query from "@/enums/query";
import type Modal from "@/enums/modal";
import * as session from "@/services/storage/sessionStorage";
import Routes from "@/enums/routes";

export enum Storage {
  BACK = "router_back",
}

/**
 * https://nextjs.org/docs/api-reference/next/router#usage-6
 */
export function useStoreBack() {
  const router = useRouter();

  React.useEffect(() => {
    const handleChange = (url: string) => {
      session.save(Storage.BACK, url);
    };

    router.events.on("routeChangeStart", handleChange);

    return () => {
      router.events.off("routeChangeStart", handleChange);
    };
  }, [router.events]);
}

export function useConsumeBack() {
  const router = useRouter();

  return React.useCallback(async () => {
    const url = session.load(Storage.BACK);

    if (url == null) {
      await router.push(Routes.INDEX);
    } else {
      router.back();
    }
  }, [router]);
}

export function useQueryReplace() {
  const router = useRouter();

  return React.useCallback(
    (query: Record<string, string | string[] | number | null | undefined>) => {
      const clean = Object.entries(query)
        .filter(([, val]) => val != null)
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

      router
        // eslint-disable-next-line no-undefined
        .replace({ query: clean }, undefined, {
          shallow: true,
          scroll: false,
        })
        .catch(() => {});
    },
    [router],
  );
}

export function useQuery(key: Query): string | null {
  const router = useRouter();

  const value = router.query[key];
  if (typeof value === "string") {
    return value;
  }

  if (Array.isArray(value)) {
    return value[0];
  }

  return null;
}

export function useQueryList(key: Query): string[] {
  const router = useRouter();

  const value = router.query[key];
  if (typeof value === "string") {
    return [value];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [];
}

export function useQueryRemove(key: Query) {
  const router = useRouter();
  const queryReplace = useQueryReplace();

  return React.useCallback(() => {
    queryReplace({ ...router.query, [key]: null });
  }, [router, queryReplace, key]);
}

export function useModal() {
  return useQuery(Query.MODAL);
}

export function useOpenModal(modal: Modal) {
  const router = useRouter();
  const queryReplace = useQueryReplace();

  return React.useCallback(() => {
    queryReplace({ ...router.query, [Query.MODAL]: modal });
  }, [router, queryReplace, modal]);
}

export function useOpenModalParam(modal: Query) {
  const router = useRouter();
  const queryReplace = useQueryReplace();

  return React.useCallback(
    (id: string) => {
      queryReplace({ ...router.query, [modal]: id });
    },
    [router, queryReplace, modal],
  );
}

export function useCloseModal(modal: Query = Query.MODAL) {
  return useQueryRemove(modal);
}
