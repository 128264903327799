/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { defineMessages, useIntl } from "react-intl";
import { SessionProvider } from "next-auth/react";
import type { SessionProviderProps } from "next-auth/react";

import "@/app.css";
import "@/fonts.css";
import "@/layout.css";
import RootProvider from "@/services/provider";
import en from "@/content/locales/en.json";
import Locale from "@/enums/locale";
import { useStoreBack } from "@/services/router";

const messages = defineMessages({
  description: {
    defaultMessage: "All aboard the gain train! 💪 🚂",
  },
});

function IndexHead() {
  const intl = useIntl();

  return (
    <Head>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>GainWagon 💪</title>

      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <meta name="theme-color" content="#FDDE03" />
      <meta name="format-detection" content="telephone=no" />

      <meta name="description" content={intl.formatMessage(messages.description)} />

      <link rel="apple-touch-icon" sizes="57x57" href="/head/gainwagon-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/head/gainwagon-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/head/gainwagon-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/head/gainwagon-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/head/gainwagon-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/head/gainwagon-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/head/gainwagon-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/head/gainwagon-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/head/gainwagon-icon-180x180.png" />

      <link rel="manifest" href="/manifest.json?v=3" />

      <link rel="icon" type="image/png" sizes="192x192" href="/head/gainwagon-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/head/gainwagon-favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/head/gainwagon-favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/head/gainwagon-favicon-16x16.png" />

      <meta name="msapplication-TileImage" content="/gainwagon-icon-144x144.png" />
    </Head>
  );
}

type SessionProps = {
  session: SessionProviderProps;
};

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps<SessionProps>) {
  useStoreBack();

  return (
    <SessionProvider session={session}>
      <RootProvider locale={Locale.EN} messages={en}>
        <IndexHead />

        <Component {...pageProps} />
      </RootProvider>
    </SessionProvider>
  );
}

export default MyApp;
