import { MDXProvider } from "@mdx-js/react";
import * as React from "react";
import type { MessageFormatElement } from "react-intl";
import { IntlProvider } from "react-intl";
import { UIDReset } from "react-uid"; // TODO remove and use React 18 API

import { PriceProvider } from "@/services/price/context";
import { MediaContextProvider } from "@/services/layout/context";
import Link from "components/Link";
import type Locale from "@/enums/locale";
import { CookiesProvider } from "@/services/cookies/context";

type WrapperProps = {
  children?: React.ReactNode;
};

function Wrapper({ children }: WrapperProps) {
  return <span className="text-main font-lighter">{children}</span>;
}

type LinkWrapperProps = {
  href?: string;
  children?: React.ReactNode;
};

function LinkWrapper({ children, href = "" }: LinkWrapperProps) {
  return <Link href={href}>{children}</Link>;
}

const components = {
  p: ({ children }: WrapperProps) => <p className="my-4">{children}</p>,
  h1: ({ children }: WrapperProps) => <h1 className="heading-1">{children}</h1>,
  h2: ({ children }: WrapperProps) => <h2 className="heading-2">{children}</h2>,
  h3: ({ children }: WrapperProps) => <h3 className="heading-3">{children}</h3>,
  ul: ({ children }: WrapperProps) => <ul className="list-disc pl-3">{children}</ul>,
  a: LinkWrapper,
  wrapper: Wrapper,
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
  locale: Locale;
  messages: Record<string, MessageFormatElement[]>;
};

export default function Provider({ children, locale, messages }: Props) {
  return (
    <UIDReset>
      <CookiesProvider>
        <IntlProvider locale={locale} messages={messages}>
          <MediaContextProvider>
            <PriceProvider>
              <MDXProvider components={components}>{children}</MDXProvider>
            </PriceProvider>
          </MediaContextProvider>
        </IntlProvider>
      </CookiesProvider>
    </UIDReset>
  );
}
