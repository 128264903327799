/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import Link from "next/link";
import type { LinkProps } from "next/link";
import classnames from "classnames";

export type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & LinkProps;

export default function LinkComponent({ className, children, href, prefetch, ...rest }: Props) {
  return (
    <Link passHref href={href} prefetch={prefetch}>
      <a className={classnames(className, "underline")} rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    </Link>
  );
}
