import * as React from "react";
import { useIntl } from "react-intl";

import { Currency } from "@/gqlgen";
import { load, save } from "@/services/price/currency";

type Context = {
  currency: Currency;
  setCurrency: (cur: Currency) => void;
};

const context = React.createContext<Context>({
  currency: Currency.Eur,
  setCurrency: () => {},
});

const { Provider } = context;

type Props = {
  children: React.ReactNode;
};

function PriceProvider({ children }: Props) {
  const [currency, setCurrency] = React.useState<Currency>(Currency.Eur);

  React.useEffect(() => {
    const cookie = load();
    if (cookie !== null) {
      setCurrency(cookie);
    }
  }, []);

  const handleSetCurrency = React.useCallback((cur: Currency) => {
    setCurrency(cur);
    save(cur);
  }, []);

  return (
    <Provider
      value={{
        currency,
        setCurrency: handleSetCurrency,
      }}
    >
      {children}
    </Provider>
  );
}

const usePrice = () => React.useContext(context);

export { PriceProvider, usePrice };

export const useFormatPrice = () => {
  const { locale } = useIntl();
  const { currency } = usePrice();

  return React.useCallback(
    (amount: number, cur: Currency | null = currency) => {
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: cur == null ? currency : cur,
        minimumFractionDigits: 0,
      }).format(amount / 100);
    },
    [locale, currency],
  );
};
