enum Query {
  // Auth
  ERROR = "error",

  // General
  ACTION = "action",
  MODAL = "modal",
  TAB = "tab",

  // Program
  BLOCK = "block",
  SESSION = "session",
  WEEK = "week",

  // Search
  FREQUENCY = "frequency",
  DURATION = "duration",
  GOAL = "goal",
  DIFFICULTY = "difficulty",
  SPLIT = "split",
  CATEGORY = "category",

  // Modals
  MODAL_EXERCISE = "modal-exercise",
  MODAL_EXERCISE_SELECT = "modal-exercise-select",
  MODAL_SELECT_EXERCISE = "modal-select-exercise",
}

export default Query;
