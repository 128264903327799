import * as React from "react";

import { cookies } from "@/services/storage";

export enum Agreed {
  TRUE = "true",
  FALSE = "false",
}

const COOKIE_AGREED = "__gw_agreed";
const COOKIE_SETTINGS = "__gw_settings";

export type Settings = {
  preferences: boolean;
  analytics: boolean;
  marketing: boolean;
};

export type Context = {
  agreed: Agreed | null;
  settings: Settings;
  onAgree: (agreed: boolean) => void;
  onChange: (settings: Settings) => void;
};

export const cookiesDefault: Context = {
  agreed: Agreed.TRUE,
  settings: {
    preferences: false,
    analytics: false,
    marketing: false,
  },
  onAgree: () => null,
  onChange: () => null,
};

const context: React.Context<Context> = React.createContext(cookiesDefault);

const { Consumer: CookiesConsumer, Provider } = context;

type Props = {
  children: React.ReactElement;
};

function CookiesProvider({ children }: Props) {
  const [agreed, setAgreed] = React.useState<Agreed | null>(Agreed.TRUE);
  const [settings, setSettings] = React.useState<Settings>(cookiesDefault.settings);

  React.useEffect(() => {
    setAgreed(cookies.load(COOKIE_AGREED) as Agreed | null);

    const val = cookies.load(COOKIE_SETTINGS);
    if (val !== null) {
      setSettings(JSON.parse(val));
    }
  }, []);

  const handleAgree = React.useCallback((agreed: boolean) => {
    const value = agreed ? Agreed.TRUE : Agreed.FALSE;
    const now = new Date();

    cookies.save(COOKIE_AGREED, value, {
      expires: new Date(now.setFullYear(now.getFullYear() + 1)),
    });

    setAgreed(value);
  }, []);

  const handleChange = React.useCallback((val: Settings) => {
    const now = new Date();

    cookies.save(COOKIE_SETTINGS, JSON.stringify(val), {
      expires: new Date(now.setFullYear(now.getFullYear() + 1)),
    });

    setSettings(val);
  }, []);

  return (
    <Provider
      value={{
        agreed,
        settings,
        onAgree: handleAgree,
        onChange: handleChange,
      }}
    >
      {children}
    </Provider>
  );
}

const useCookies = () => React.useContext(context);

export { CookiesProvider, CookiesConsumer, useCookies };
