import { cookies } from "@/services/storage";
import type { Currency } from "@/gqlgen";

export const COOKIE = "currency";

export const save = (currency: Currency) => cookies.save(COOKIE, currency);

export const load = (): Currency | null => cookies.load(COOKIE) as Currency | null;

export const remove = () => cookies.remove(COOKIE);
