enum Routes {
  // 200
  TWO_HUNDRED = "/200",

  // Account
  ACCOUNT = "/account",
  ACCOUNT_SETTINGS = "/account/settings",

  // Admin
  ADMIN = "/admin",
  ADMIN_EXERCISES = "/admin/exercises",
  ADMIN_EXERCISE_TYPES = "/admin/exercise-types",
  ADMIN_PROGRAM = "/admin/program/:id",

  // Auth
  AUTH_ERROR = "/auth/error",
  AUTH_SIGN_IN = "/auth/sign-in",
  AUTH_VERIFY_REQUEST = "/auth/verify-request",

  // Buy
  BUY = "/buy/:slug",

  // Deep
  DEEP_ACCOUNT = "/deep/account",

  // Exercise
  EXERCISE = "/exercise/:slug",

  // Index
  INDEX = "/",

  // Pages
  PAGES_COOKIES = "/pages/cookies",
  PAGES_EXERCISES = "/pages/exercises",
  PAGES_FAQ = "/pages/faq",
  PAGES_PRIVACY = "/pages/privacy",
  PAGES_TERMS = "/pages/terms",

  // Program
  PROGRAM = "/program/:slug",

  // Search
  SEARCH = "/search",

  // Train
  TRAIN = "/train/:slug",

  // Trainer
  TRAINER = "/trainer/:id",
}

export default Routes;

export type Params = {
  [Routes.ADMIN_PROGRAM]: { id: string };
  [Routes.BUY]: { slug: string };
  [Routes.EXERCISE]: { slug: string };
  [Routes.PROGRAM]: { slug: string };
  [Routes.TRAIN]: { slug: string };
  [Routes.TRAINER]: { id: string };
};
