import { createMedia } from "@artsy/fresnel";

// TODO move this level up, rename file to `layout.tsx`
const mediaContext = createMedia({
  breakpoints: {
    sm: 0,
    lg: 768,
  },
});

export const { MediaContextProvider, Media } = mediaContext;

export const mediaStyle = mediaContext.createMediaStyle();
